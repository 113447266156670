<template>
  <div id="dangerousArea"></div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  props:{
    form:{
      type:Object,
      default:()=>{}
    }
  },
  data() {
    return {
      visible: false,
      map: null,
      AMap: null,
      mouseTool:null,
      polygon:null,
    };
  },
  async mounted() {
    const fence = this.form?.fenceList?.[0]
    let position = fence?[fence.longitude,fence.latitude]:[118.08891,24.479627]
    await this.initMap(position);
    await this.getElectronFence()
  },
  methods: {
    async getElectronFence(){
      if(this.form?.fenceList?.length){
        let path = [];
        this.form.fenceList.forEach(item=>{
          path.push(new AMap.LngLat(item.longitude,item.latitude))
        })
        this.polygon = new AMap.Polygon({
          path: path,
          fillColor: '#9CC959', // 多边形填充颜色
          fillOpacity:'0.5',
          borderWeight: 2, // 线条宽度，默认为 1
          strokeColor: '#9CC959', // 线条颜色
        });
        this.map.add(this.polygon)
      }else{
        this.mouseTool = new AMap.MouseTool(this.map);
        this.mouseTool.polygon({
          fillColor: '#9CC959', // 多边形填充颜色
          fillOpacity:'0.5',
          borderWeight: 2, // 线条宽度，默认为 1
          strokeColor: '#9CC959 ', // 线条颜色
        });
        this.mouseTool.on('draw',this.draw)
      }
    },
    async clearPolygon(){
      if(this.polygon){
        this.map.remove(this.polygon)
      }
      this.$emit('update:form',{
        ...this.form,
        fenceList:[]
      })
      this.mouseTool = new AMap.MouseTool(this.map);
      this.mouseTool.polygon({
        fillColor: '#9CC959', // 多边形填充颜色
        fillOpacity:'0.5',
        borderWeight: 2, // 线条宽度，默认为 1
        strokeColor: '#9CC959', // 线条颜色
      });
      this.mouseTool.on('draw',this.draw)
      this.$message.success('清除成功')
    },
    // 初始化地图
    async initMap(position) {
      this.AMap = await AMapLoader.load({
        key: "a0431274a06861ab57ac681244e7d20f", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.MouseTool'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      });
      this.map = new this.AMap.Map("dangerousArea", {
        //设置地图容器id
        // viewMode:"3D",    //是否为3D地图模式
        zoom: 12, //初始化地图级别
        center: position, //初始化地图中心点位置
        // mapStyle: "amap://styles/darkblue",
      });
      this.map.on("complete", () => {
        console.log("地图加载成功");
      });
    },
    async draw(e){
      const fenceList = []
      const path = e.obj.getPath()
      console.log(path)
      path.forEach(item=>{
        fenceList.push({
          latitude:item.lat,
          longitude:item.lng
        })
      })
      this.polygon = e.obj
      console.log(fenceList)
      this.$emit('update:form',{
        ...this.form,
        fenceList
      })
      this.mouseTool.close(false)
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/views/avue-table.scss";
#dangerousArea {
  width: 100%;
  height: 370px;
}
</style>
