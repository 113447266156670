import request from "@/utils/request";

// 新增危险区域
export const addDangerous = (data)=>{
  return request({
    url: "/electron/eledangerarea",
    method: "post",
    data,
  });
}
//编辑危险区域
export const editDangerous = (data)=>{
  return request({
    url: "/electron/eledangerarea",
    method: "put",
    data,
  });
}
//分页查询
export const getDangerousList = (params)=>{
  return request({
    url: "/electron/eledangerarea/page",
    method: "get",
    params,
  });
}
//通过ID查询
export const getDangerousById = (id)=>{
  return request({
    url: `/electron/eledangerarea/${id}`,
    method: "get",
  });
}
//删除危险区域
export const deleteDangerous = (id)=>{
  return request({
    url: `/electron/eledangerarea/${id}`,
    method: "delete",
  });
}
//更改危险区域状态
export const changeDangerousStatus = (params)=>{
  return request({
    url: `/electron/eledangerarea`,
    method: "get",
    params,
  });
}
