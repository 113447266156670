export const tableOption = {
  border: true,
  stripe: true,
  menuAlign: "center",
  searchMenuSpan: 6,
  tip: false,
  editBtn: false,
  selection: true,
  delBtn: false,
  align: "center",
  addBtn: false,
  menu: true,
  column: [
    {
      fixed: true,
      label: "区域名称",
      prop: "name",
      span: 24,
      rules: [
        {
          required: true,
          message: "请输入区域名称",
          trigger: "blur",
        },
      ],
    },
    {
      fixed: true,
      label: "添加人",
      prop: "submitter",
      span: 24,
      addDisplay:false,
      editDisplay:false
    },
    {
      fixed: true,
      label: "添加时间",
      prop: "createTime",
      span: 24,
      addDisplay:false,
      editDisplay:false
    },
    {
      fixed: true,
      label: "启用状态",
      prop: "status",
      type:'switch',
      dicData: [
        {
          label: "禁用",
          value: 0,
        },
        {
          label: "启用",
          value: 1,
        },
      ],
      rules: [
        {
          required: true,
          message: "请选择启用状态",
          trigger: "blur",
        },
      ],
      span: 24,
    },
    {
      fixed: true,
      label: "危险区域",
      prop: "fenceList",
      hide:true,
      span: 24,
      rules: [
        {
          required: true,
          message: "请选择危险区域",
          trigger: "blur",
        },
      ],
    },
  ],
};
